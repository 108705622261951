<template>
  <v-container fluid>
    <v-row>
      <v-spacer></v-spacer>
      <v-btn class="white" @click="exportToExcel()"
        ><v-icon color="green">mdi-microsoft-excel</v-icon
        ><v-icon color="green">mdi-download</v-icon></v-btn
      >
    </v-row>

    <br />

    <v-data-table
      :headers="headers"
      :items="items"
      item-key="name"
      class="custom-table"
      :page.sync="page"
      :items-per-page.sync="itemsPerPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :server-items-length.sync="serverItemsLength"
      :footer-props="{
        'items-per-page-options': [10, 25, 50],
      }"
      @update:sort-by="updateSortBy"
      @update:sort-desc="updateSortDesc"
      @update:items-per-page="updateRowsPerPage"
      @update:page="updatePage"
      ref="dataTable"
    >
      <template v-slot:item.VL_DI="{ item }">
        <span v-money-format="item.VL_DI"></span>
      </template>

      <template v-slot:item.VL_DESP_PROCESSO="{ item }">
        <span v-money-format="item.VL_DESP_PROCESSO"></span>
      </template>

      <template v-slot:item.VL_DESP_NF_SERV="{ item }">
        <span v-money-format="item.VL_DESP_NF_SERV"></span>
      </template>

      <template v-slot:item.VL_NF_ENTRADA="{ item }">
        <span v-money-format="item.VL_NF_ENTRADA"></span>
      </template>

      <template v-slot:item.VL_NF_SAIDA="{ item }">
        <span v-money-format="item.VL_NF_SAIDA"></span>
      </template>

      <template v-slot:item.VL_LANC_CRED="{ item }">
        <span v-money-format="item.VL_LANC_CRED"></span>
      </template>

      <template v-slot:item.VL_LANC_DEB="{ item }">
        <span v-money-format="item.VL_LANC_DEB"></span>
      </template>

      <template v-slot:item.VL_ADIANTAMENTOS="{ item }">
        <span v-money-format="item.VL_ADIANTAMENTOS"></span>
      </template>

      <template v-slot:item.VL_DIF_FORN_PROC="{ item }">
        <span v-money-format="item.VL_DIF_FORN_PROC"></span>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import strapiApi from "../../../../libraries/strapi/strapiApi";
import { mapState } from "vuex";
import DateInput from "../../components/fields/DateInput";
let dateStart = "2023-01-01";
import VCurrencyField from "../../components/VCurrencyField.vue";
import { read, utils, writeFile } from "xlsx";

export default {
  name: "TabelaProcessosTotais",
  components: { DateInput, VCurrencyField },

  props: ["process", "sort", "sortBy", "sortDesc", "lastRefresh"],

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  watch: {
    lastRefresh(val) {
      this.doSearch();
    },
    showDialog(val) {
      this.message = "";
    },
    showDialogEdit(val) {
      this.message = "";
    },
  },
  data() {
    return {
      menu: false,
      showDialog: false,
      showDialogEdit: false,
      showDialogReport: false,
      expenses: [],
      itemSelected: {},
      page: 1,
      itemsPerPage: 50,
      serverItemsLength: 10,
      items: [],
      message: "",
      NF_EMITIDA: false,
      headers: [
        {
          text: "Descrição",
          align: "start",
          sortable: false,
          value: "DESCRICAO",
        },

        {
          text: "VL_DI",
          align: "start",
          sortable: false,
          value: "VL_DI",
        },
        {
          text: "VL_DESP_PROCESSO",
          align: "start",
          sortable: false,
          value: "VL_DESP_PROCESSO",
        },
        {
          text: "VL_DESP_NF_SERV",
          align: "start",
          sortable: false,
          value: "VL_DESP_NF_SERV",
        },

        {
          text: "VL_NF_ENTRADA",
          align: "start",
          sortable: false,
          value: "VL_NF_ENTRADA",
        },
        {
          text: "VL_NF_SAIDA",
          align: "start",
          sortable: false,
          value: "VL_NF_SAIDA",
        },
        {
          text: "VL_LANC_CRED",
          align: "start",
          sortable: false,
          value: "VL_LANC_CRED",
        },

        {
          text: "VL_LANC_DEB",
          align: "start",
          sortable: false,
          value: "VL_LANC_DEB",
        },

        {
          text: "VL_ADIANTAMENTOS",
          align: "start",
          sortable: false,
          value: "VL_ADIANTAMENTOS",
        },

        {
          text: "VL_DIF_FORN_PROC",
          align: "start",
          sortable: false,
          value: "VL_DIF_FORN_PROC",
        },
      ],
    };
  },

  async mounted() {
    await this.doSearch();
  },
  methods: {
    async exportToExcel() {
      let data = this.items.map((el) => {
        let row = {};
        for (var i = 0; i < this.headers.length; i++) {
          let itemHeader = this.headers[i];
          if (itemHeader.text && itemHeader.value)
            if (itemHeader.value !== "id")
              row[itemHeader.text] = el[itemHeader.value];
        }

        return row;
      });

      const ws = utils.json_to_sheet(data);

      const wb = utils.book_new();

      utils.book_append_sheet(wb, ws, "Relatório de despesas");

      writeFile(wb, "Relatório de despesas.xlsx");
    },

    async updatePage() {
      await this.doSearch();
    },

    async updateSortBy() {
      await this.doSearch();
    },

    async updateSortDesc() {
      await this.doSearch();
    },

    async updatePage() {
      await this.doSearch();
    },

    async updateRowsPerPage() {
      await this.doSearch();
    },
    async doSearch() {
      this.loading = true;

      let filters = {
        PRI_COD: { eq: this.process?.PRI_COD },
        FIL_COD: { eq: this.process?.FIL_COD },
      };

      let { values, pagination } =
        await strapiApi.vwFechProcessosTotais.getWithPagination(
          this.page,
          this.itemsPerPage,
          //this.sort,
          //this.sortBy,
          "",
          false,
          filters
          //{}
        );

      let fields = [
        "VL_DI",
        "VL_DESP_PROCESSO",
        "VL_DESP_NF_SERV",
        "VL_NF_ENTRADA",
        "VL_NF_SAIDA",
        "VL_LANC_CRED",
        "VL_LANC_DEB",
        "VL_ADIANTAMENTOS",
        "VL_DIF_FORN_PROC",
      ];

      let totalsLine = { DESCRICAO: "TOTAL" };
      for (let i = 0; i < fields.length; i++) {
        let field = fields[i];
        totalsLine[field] = values?.reduce(
          (acc, curr) => acc + curr[field] ?? 0,
          0
        );
      }
      this.items = [...values, totalsLine];

      this.serverItemsLength = pagination?.total;

      this.loading = false;
    },
  },
};
</script>

<style></style>
